import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/dist/query'
import amountSlice from './features/amountTopup'
import itemCartSlice from './features/productCheckout'
import lastPathnameSlice from './features/lastPathname'
import getCartSlice from './features/getCart'
import itemIDSlice from './features/itemID'

export const store = configureStore({
  reducer: {
    amountTopup: amountSlice,
    cart: getCartSlice,
    lastPathname: lastPathnameSlice,
    itemCart: itemCartSlice,
    itemID: itemIDSlice,
  },
})

setupListeners(store.dispatch)
// Export the store's dispatch function type
export const AppDispatch = store.dispatch
