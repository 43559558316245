// Reducers
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  item: [],
}

const itemIDSlice = createSlice({
  name: 'itemID',
  initialState,
  reducers: {
    addItemID: (state, action) => {
      state.item = action.payload
      // console.log(current(state))
    },
  },
})
export const { addItemID } = itemIDSlice.actions
export default itemIDSlice.reducer
