// Reducers
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  lastPathname: '/',
}

const lastPathnameSlice = createSlice({
  name: 'lastPathname',
  initialState,
  reducers: {
    addLastPathname: (state, action) => {
      state.lastPathname = action.payload
      // console.log(current(state))
    },
  },
})
export const { addLastPathname } = lastPathnameSlice.actions
export default lastPathnameSlice.reducer
