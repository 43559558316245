// Reducers
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  amount: 0,
}

const amountSlice = createSlice({
  name: 'amontTopup',
  initialState,
  reducers: {
    addAmount: (state, action) => {
      state.amount = action.payload
    },
  },
})
export const { addAmount } = amountSlice.actions
export default amountSlice.reducer
