import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import axios from 'axios'

const initialState = {
  items: {},
  loading: false,
  error: null,
}

// Define the action type as a constant
export const CLEAR_CART = 'itemCart/clearCart'

export const deleteProductAsync = createAsyncThunk(
  'itemCart/deleteProductAsync',
  async ({ storeKey, productIndex, productID }, { rejectWithValue }) => {
    try {
      await axios.delete(`/api/cart?item_id=${productID}`)
      return { storeKey, productIndex }
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

const itemCartSlice = createSlice({
  name: 'itemCart',
  initialState,
  reducers: {
    itemProductCart: (state, action) => {
      state.items = action.payload
    },
    clearCart: (state) => {
      state.items = {}
    },
    deleteProduct: (state, action) => {
      const { storeKey, productIndex } = action.payload
      if (state.items[storeKey]) {
        // Filter out the product by index
        const updatedProducts = state.items[storeKey].filter(
          (_, index) => index !== productIndex,
        )
        if (updatedProducts.length === 0) {
          // If the array is empty after filtering, remove the key from the object
          delete state.items[storeKey]
        } else {
          // Otherwise, update the array for the storeKey
          state.items[storeKey] = updatedProducts
        }
      }
    },
    extraReducers: (builder) => {
      builder
        .addCase(deleteProductAsync.pending, (state) => {
          state.loading = true
        })
        .addCase(deleteProductAsync.fulfilled, (state) => {
          // Just clear the loading state, the item has already been removed from the state
          state.loading = false
        })
        .addCase(deleteProductAsync.rejected, (state, action) => {
          state.loading = false
          state.error = action.payload
        })
        .addCase(clearCart, (state) => {
          // Handle the clearCart action
          state.items = {}
        })
    },
  },
})

export const { itemProductCart, deleteProduct, clearCart } =
  itemCartSlice.actions

export const selectItemCart = (state) => state.itemCart?.items

export default itemCartSlice.reducer
