// Reducers
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

const initialState = {
  cart: [],
}
// Define your asynchronous action using createAsyncThunk
export const fetchCartData = createAsyncThunk(
  'carts/fetchCartData',
  async () => {
    const response = await axios.get('/api/cart') // Adjust the URL to your API endpoint
    return response.data
  },
)

const getCartSlice = createSlice({
  name: 'carts',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchCartData.fulfilled, (state, action) => {
        state.cart = action.payload
      })
      .addCase(fetchCartData.rejected, (action) => {
        console.log('Fetch cart data error:', action.error)
      })
  },
})
export default getCartSlice.reducer
